import { useEffect, useState } from 'react';
import './users.styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UsersService from '../../../../services/users.service';
import Loader from '../../../../components/loader/loader.component';

function UsersPage() {

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any[]>([]);
    const [start, setStart] = useState<number>(0);
    const [activated, setActivated] = useState<boolean>(true);
    const count = 50;

    useEffect(() => {
        setLoading(true);

        // Getting the users list
        UsersService.list({ start, count, activated })
            .then((users) => {
                setUsers(users);
                setLoading(false);
            })
            .catch(err => toast.error(err.status));

    }, [activated]);

    /**
     * Modifies the activated value to modify
     * the users view.
     * @param data the activated view value
     */
    function modifyUsersView(data: any) {
        console.log(data);
    }

    return <div className='subpage-window'>

        <h2 className="subpage-title">Felhasználók</h2>

        {loading && <div className='subpage-loader'>
            <Loader />
        </div>}

        {!loading && <div className='fade-in-up'>
            <div id='users-controls'>
                <select onChange={(_event: any) => modifyUsersView(_event.target.value)}>
                    <option value='true'>Felhasználók</option>
                    <option value='false'>Jelentkezések</option>
                </select>
                <input id='users-search-input' placeholder='Keresés e-mail cím alapján' />
            </div>
            <table className='light-shadow user-list-table'>
                <thead>
                    <tr>
                        <th>Név</th>
                        <th>E-mail</th>
                        <th>Telefonszám</th>
                        <th>Státusz</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: any) => (
                        <tr key={user.email}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.telephone}</td>
                            <td>
                                {user.enabled && <div className='user-status'><div className='user-status-circle user-status-circle-active'></div>Aktív</div>}
                                {!user.enabled && <div className='user-status'><div className='user-status-circle user-status-circle-blocked'></div>Tiltott</div>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>}
        <ToastContainer />
    </div>
}

export default UsersPage;