import { Form, useNavigate, useNavigation } from 'react-router-dom';
import './login.styles.css';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TiszaLogo from '../../assets/tiszalogo.png';

import AuthenticationService from '../../services/authentication.service';


function LoginPage(props: any) {

    sessionStorage.removeItem("USER");

    const navigate = useNavigate();

    const [emailCodeSent, setEmailCodeSent] = useState<boolean>(false);
    const [codeSending, setCodeSending] = useState<boolean>(false);
    const [logginIn, setLoggingIn] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');

    /**
     * Sends the OTP authentication code
     * the the user's email address.
     */
    function sendCode(): void {
        AuthenticationService.otp({ email })
            .then(() => {
                setEmailCodeSent(true);
            })
            .catch(err => toast.error(err.status));
    }

    /**
     * Sends the login request to the API server.
     * On success it saves the authentication token
     * and the user data in the session storage.
     */
    function login(): void {
        setLoggingIn(true);
        AuthenticationService.login({ email, code })
            .then((data) => {
                setLoggingIn(false);
                sessionStorage.setItem('USER', JSON.stringify(data));
                navigate('/home/island');
            })
            .catch(err => {
                setLoggingIn(false);
                toast.error(err.status)
            });
    }

    return <div id="login-window">
        <div id="login-window-overlay">
            <img id="login-panel-logo" src={TiszaLogo} />
            <div id="login-panel" className='light-shadow fade-in-up'>
                <div id="login-panel-header">
                    <h3 id="login-panel-title">Bejelentkezés</h3>
                </div>
                <div id="login-panel-form">
                    {!emailCodeSent && <p className='registration-panel-description'>Add meg az e-mail címed a bejelentkezési kód kéréséhez!</p>}
                    {emailCodeSent && <p className='registration-panel-description'>A megadott e-mail címre ({email}) elküldtük a bejelentkezéshez szükséges kódot!</p>}

                    {!emailCodeSent && <input onKeyUp={(_event: any) => setEmail(_event.target.value)} name='email' className='login-panel-input' placeholder='E-mail cím' />}
                    {!emailCodeSent && <button disabled={codeSending} type='button' onClick={sendCode} className='login-panel-button primary-button minimum-shadow'>Kód küldése</button>}

                    {emailCodeSent && <input onKeyUp={(_event: any) => setCode(_event.target.value)} name='code' className='login-panel-input' placeholder='Bejelentkezési kód' />}
                    {emailCodeSent && <button disabled={logginIn} onClick={login} className='login-panel-button primary-button minimum-shadow'>Bejelentkezés</button>}

                </div>
                <div>
                    <p onClick={() => navigate('/registration')} id="login-registration-text">Regisztrációhoz kattints ide!</p>
                </div>
            </div>
            <ToastContainer />
        </div>
    </div>
}

export default LoginPage;