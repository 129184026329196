import { Outlet, useNavigate } from 'react-router-dom';
import './home.styles.css';
import EarthIcon from '../../icons/earth.icon';
import IdCardIcon from '../../icons/id-card.icon';
import UserIcon from '../../icons/user.icon';
import DoorOpenIcon from '../../icons/door-open.icon';
import UserGroupIcon from '../../icons/user-group.icon';
import { authProvider } from '../../auth.provider';
import MailboxIcon from '../../icons/mailbox.icon';
import EnvelopeIcon from '../../icons/envelope.icon';
import IslandTropicalIcon from '../../icons/island.icon';
import TiszaLogo from '../../assets/tiszalogo.png';
import { useEffect, useRef, useState } from 'react';

function HomePage() {

    const navigate = useNavigate();

    const sidebarRef = useRef<any>(null);
    const dynamicPaddingRef = useRef<any>(null);
    const [padding, setPadding] = useState<any>('0px');

    useEffect(() => {
        const updatePadding = () => {
            if (sidebarRef.current) {
                console.log('UPA');
                const referenceWidth = sidebarRef.current.offsetWidth;
                setPadding(`${referenceWidth}px`);
            }
        };

        // Initial update
        updatePadding();

        // Update padding on window resize
        window.addEventListener('resize', updatePadding);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', updatePadding);
        };
    }, []);

    let user: any = sessionStorage.getItem('USER');
    if (!user) {
        console.error('User data not found');
        sessionStorage.removeItem('SESSION_KEY');
        navigate('/login');
    } else {
        user = JSON.parse(user);
    }

    /**
     * Logs the user out from the current session.
     */
    function logout() {
        authProvider.signout();
        navigate('/login');
    };

    return <>
        {user != null && <div id='home-window'>
            <div ref={sidebarRef} id='home-menu-sidebar' className='minimum-shadow'>
                <div id='signed-in-user-panel'>
                    <img id="signed-in-user-icon" src={TiszaLogo} />
                    <div>
                        <h4 id='signed-in-user-name'>{user.name}</h4>
                        <p id='signed-in-user-type'>{user.email}</p>
                    </div>
                </div>

                <div id='home-menu-buttons'>
                    <div className='home-menu-button'>
                        <EnvelopeIcon className='home-menu-button-icon' />
                        Üzenetek
                    </div>
                    <div onClick={() => navigate('/home/island')} className='home-menu-button'>
                        <IslandTropicalIcon className='home-menu-button-icon' />
                        Szigetem
                    </div>
                    {user.roles.includes('ADMIN') && <div onClick={() => navigate('/home/users')} className='home-menu-button'>
                        <UserGroupIcon className='home-menu-button-icon' />
                        Felhasználók
                    </div>}
                    <div onClick={() => navigate('/home/profile')} className='home-menu-button'>
                        <UserIcon className='home-menu-button-icon home-menu-button-user-icon' />
                        Profil
                    </div>
                    <div onClick={logout} className='home-menu-button'>
                        <DoorOpenIcon className='home-menu-button-icon' />
                        Kijelentkezés
                    </div>
                </div>

            </div>
            <div style={{ paddingLeft: padding }} ref={dynamicPaddingRef} id='home-subpages-window'>
                <Outlet />
            </div>
        </div>}
    </>
}

export default HomePage;