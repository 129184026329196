
/**
 * Fetches the user list from the API server with
 * the given filters in the payload.
 * @param payload the payload to send
 * @returns on success it returns the users list
 */
async function list(payload: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const sessionToken = sessionStorage.getItem('SESSION_KEY');
            const response = await fetch(`/api/v1/user/list?start=${payload.start}&count=${payload.count}&activated=${payload.activated}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${sessionToken}`
                }
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                return reject(errorResponse);
            }

            // On refresh save the new JWT token
            const jwtToken = response.headers.get('X-Session-Token');
            if (jwtToken != null) {
                sessionStorage.setItem('SESSION_KEY', jwtToken);
            }

            const data = await response.json();
            resolve(data.data);
        } catch (err: any) {
            console.error(err);
            reject(err);
        }
    });
}

export default {
    list
};